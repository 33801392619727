import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import styles from './ImageText.module.scss';
import Picture from '../../Picture/Picture';
import Source from '../../Picture/Source';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';
import type { ImageText as ImageTextData } from '../ComponentTypes';
import { IMAGE_TEXT_LAYOUT_DARK, IMAGE_TEXT_LAYOUT_LIGHT } from '../ComponentTypes';

interface Props {
    component: ImageTextData;
    wrapper?: ReactElement;
}

const ImageText: React.FC<Props> = ({ component, wrapper }) => {
    const withStaticUrl = useWithStaticUrl();

    const trimmedText = component.text.trim();

    const renderImage = () => {
        if (!component.image.imageUri) {
            return null;
        }

        if (component.layout === IMAGE_TEXT_LAYOUT_LIGHT) {
            return <Picture alt={component.image.alt || ''} imageUrl={withStaticUrl(component.image.imageUri)}>
                <Source background={false} ratio={false} imageWidth={500} />
            </Picture>;
        }

        if (component.layout === IMAGE_TEXT_LAYOUT_DARK) {
            return <Picture alt={component.image.alt || ''} imageUrl={withStaticUrl(component.image.imageUri)}>
                <Source background={false} ratio={false} imageWidth={2000} minScreenWidth={2000} />
                <Source background={false} ratio={false} imageWidth={1440} minScreenWidth={1440} />
                <Source background={false} ratio={false} imageWidth={1200} minScreenWidth={1200} />
                <Source background={false} ratio={false} imageWidth={768} />
            </Picture>;
        }

        return null;
    };

    return <div className={classNames(
        'section',
        { 'pt-0': component.layout === IMAGE_TEXT_LAYOUT_LIGHT },
        { 'section--xl': component.layout === IMAGE_TEXT_LAYOUT_DARK },
    )}>
        <div className={classNames(
            styles.ImageText,
            { [styles.ImageText_layout_dark as string]: component.layout === IMAGE_TEXT_LAYOUT_DARK },
            { [styles.ImageText_layout_light as string]: component.layout === IMAGE_TEXT_LAYOUT_LIGHT },
        )}>
            <div className={styles.ImageText__image}>
                {renderImage()}
            </div>
            <div className={styles.ImageText__content}>
                <div className={styles.ImageText__content__container}>
                    <div className="text-component lh-lg">
                        <div className="text-component__text">
                            {wrapper
                                ? React.cloneElement(wrapper, {}, trimmedText)
                                : <Markdown options={{ disableParsingRawHTML: true }}>{trimmedText}</Markdown>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default ImageText;
