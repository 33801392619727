import type { ReactElement, PropsWithChildren } from 'react';
import React, { useRef } from 'react';
import classNames from 'classnames';
import Picture from '../../Picture/Picture';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';
import type { Box as BoxComponentType, BoxType } from '../ComponentTypes';
import { BOX_TYPE_MEDIUM, BOX_TYPE_LARGE, BOX_TYPE_SMALL } from '../ComponentTypes';
import Button from '../../Button/Button';
import styles from './Box.module.scss';
import Source from '../../Picture/Source';

type BoxProps = {
    editButtons?: ReactElement;
    editable: boolean;
    index: number;
    item: BoxComponentType;
    onTitleChange?: (value: string, index: number) => void;
    titleWrapper?: ReactElement;
    type: BoxType;
};

const Box: React.FC<PropsWithChildren<BoxProps>> = ({
    children, editButtons, editable, index, item, onTitleChange, titleWrapper, type,
}) => {
    const withStaticUrl = useWithStaticUrl();
    const titleRef = useRef(item.title);
    const isBoxSmall = type === BOX_TYPE_SMALL;
    const isBoxMedium = type === BOX_TYPE_MEDIUM;
    const isBoxLarge = type === BOX_TYPE_LARGE;

    const picture = children || (
        <Picture alt={item.title ?? ''} imageUrl={withStaticUrl(item.imageUri)}>
            <Source imageWidth={isBoxSmall || isBoxMedium ? 140 : 322} minScreenWidth={768} />
            <Source imageWidth={isBoxSmall || isBoxMedium ? 140 : 164} />
        </Picture>
    );

    return (
        <div className={classNames(
            { 'is-col-1': !isBoxMedium },
            styles.Box,
        )}>
            <div className={classNames(
                { [styles.Box__small as string]: isBoxSmall },
                { [styles.Box__medium as string]: isBoxMedium },
                { [styles.Box__large as string]: isBoxLarge },
            )}>
                <div data-testid="box-image" className={classNames(styles.Box__image, { deactivated: editable && item.pageId && !item.url })}>
                    {item.imageUri && picture }
                    {isBoxSmall && (<Button
                        iconOnly
                        isWhite
                        icon="fa-arrow-right-long"
                        aria-label="go to link"
                        className={classNames(styles.Box__image__button, 'button--round')}>
                    </Button>)}
                </div>
                <div className={`${styles.Box__content as string} lh-md`}>
                    {editable && titleWrapper && onTitleChange
                        ? React.cloneElement(
                            titleWrapper,
                            { onChange: (value: string) => onTitleChange(value, index) },
                            <p>{titleRef.current || 'Enter your title'}</p>,
                        )
                        : <p>{item.title}</p>}
                </div>
                {!editable && item.url && <a aria-label='go to link' className='expanded-link' draggable={false}
                    href={item.urlParams ? `${item.url}${item.urlParams}` : item.url} title={item.title}/> }
                {editButtons}
            </div>
        </div>
    );
};

export default Box;
