import type React from 'react';
import type { Button as BannerComponentData } from '../ComponentTypes';
import { useIsInitialRequestMobile } from '../../WebshopContext/WebshopContext';
import ButtonLink from '../../Button/Button';
import { useIsMobile } from '../../hooks/useSize';

interface Props {
    component: BannerComponentData;
}

const Button: React.FC<Props> = ({ component }) => {
    const isMobileOrTablet = useIsMobile(useIsInitialRequestMobile());

    if (!component.text) {
        return null;
    }

    if (!isMobileOrTablet && component.hideDesktop) {
        return null;
    }

    return (
        <div className="section pt-0">
            <div className="wrapper is-centered">
                <ButtonLink href={component.url} isGhost>{component.text}</ButtonLink>
            </div>
        </div>
    );
};

export default Button;
